import React from "react";

interface GTCLogoProps {
  color?: string;
  width?: string;
  height?: string;
}

const GTCLogo: React.FC<GTCLogoProps> = ({ color }) => {
  const fillColor = color || "white";
  return (
    <svg
      width="160.2"
      height="70"
      viewBox="0 0 87 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.404 32.6038C34.8764 32.6904 32.3638 32.8309 29.8675 33.024L42.8764 23.9177C42.9651 23.9434 43.0583 23.9635 43.1549 23.9774L37.404 32.6038Z"
        fill={fillColor}
      />
      <path
        d="M46.0499 32.5187C45.1926 32.5062 44.3337 32.5 43.4732 32.5C42.6126 32.5 41.7537 32.5062 40.8964 32.5187L43.3316 23.9956C43.3782 23.9985 43.4254 24 43.4732 24C43.5209 24 43.5682 23.9985 43.6148 23.9956L46.0499 32.5187Z"
        fill={fillColor}
      />
      <path
        d="M57.0789 33.024C54.5826 32.8309 52.07 32.6904 49.5424 32.6038L43.7915 23.9774C43.8881 23.9635 43.9812 23.9434 44.0699 23.9177L57.0789 33.024Z"
        fill={fillColor}
      />
      <path
        d="M0 37.9911L42.5731 23.8C42.6284 23.8277 42.6866 23.8529 42.7475 23.8754L23.9867 33.5792C15.7836 34.4943 7.77185 35.9798 0 37.9911Z"
        fill={fillColor}
      />
      <path
        d="M86.9466 37.9912C79.1747 35.9798 71.1629 34.4943 62.9597 33.5792L44.1989 23.8754C44.2597 23.8529 44.318 23.8277 44.3732 23.8L86.9466 37.9912Z"
        fill={fillColor}
      />
      <path
        d="M11.6186 34.524C11.1985 30.1676 11.6908 25.7714 13.0641 21.6158C14.4374 17.4602 16.6616 13.6364 19.5949 10.3882C22.5282 7.14005 26.1061 4.53876 30.1006 2.75024C34.0951 0.96173 38.4185 0.0252445 42.7951 0.000503373C47.1716 -0.0242378 51.5053 0.863309 55.5198 2.60655C59.5343 4.34978 63.1414 6.91045 66.1112 10.1253C69.0811 13.3401 71.3484 17.1385 72.7686 21.2783C74.1888 25.4181 74.7307 29.8084 74.3599 34.1693L71.3737 33.9153C71.7093 29.9693 71.2189 25.9967 69.9338 22.2508C68.6487 18.5049 66.5971 15.0678 63.9099 12.1589C61.2226 9.24992 57.9586 6.93287 54.3261 5.35548C50.6936 3.7781 46.7722 2.97499 42.812 2.99738C38.8519 3.01977 34.9398 3.86716 31.3253 5.48551C27.7109 7.10386 24.4733 9.45766 21.8191 12.3968C19.1649 15.336 17.1523 18.796 15.9096 22.5562C14.667 26.3164 14.2215 30.2943 14.6017 34.2363L11.6186 34.524Z"
        fill={fillColor}
      />
      <path
        d="M43.4732 4.5L44.9732 12L52.4732 13.5L44.9732 15L43.4732 22.5L41.9732 15L34.4732 13.5L41.9732 12L43.4732 4.5Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default GTCLogo;
